import { graphql, useStaticQuery } from 'gatsby';

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
        edges {
          node {
            title
            slug
            author {
              name
            }
            publishDate(formatString: "MMMM Do, YYYY")
            tags
            heroImage {
              fluid(resizingBehavior: SCALE, cropFocus: CENTER) {
                ...GatsbyContentfulFluid
              }
            }
            description {
              description
            }
            body {
              body
            }
          }
        }
      }
    }
  `);
  return data.allContentfulBlogPost.edges.map((post) => {
    const {
      author,
      body,
      description,
      heroImage,
      publishDate,
      slug,
      tags,
      title,
    } = post.node;
    return {
      title,
      author: author?.name,
      slug,
      tags,
      body,
      excerpt: description,
      image: heroImage,
      date: publishDate,
    };
  });
};

export default usePosts;
// heroImage {
//               fluid(
//                 maxWidth: 384
//                 maxHeight: 218
//                 resizingBehavior: SCALE
//                 cropFocus: CENTER
//               ) {
//                 ...GatsbyContentfulFluid
//               }
//             }
