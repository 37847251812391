import styled from 'styled-components';
import { Link } from 'gatsby';
import { widthFrom, widthUntil } from '@theme/theme';

const ArticlesLink = styled(Link).attrs((props) => ({
  className:
    'text-3xl leading-7 font-medium text-darkBlue hover:text-darkBlue mt-4',
}))`
  ${widthUntil.MOBILE`
      font-size: ${({ theme }) => theme.fontSizes.body};
  `}
`;

const PostsContainer = styled.div.attrs((props) => ({
  className: '',
}))``;

const PostsPreviewContainer = styled.div.attrs((props) => ({
  className: 'bg-white',
}))``;

export { PostsContainer, ArticlesLink, PostsPreviewContainer };
