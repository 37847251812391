import React from 'react';
import usePosts from '@hooks/use-posts';
import Posts from '@components/Posts';
import PropTypes from 'prop-types';
import { PostsPreviewContainer, PostsContainer } from './BlogPreview.styled';

const BlogPreview = () => {
  const posts = usePosts();
  return (
    <PostsPreviewContainer>
      <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-12">
        <div className="space-y-12">
          <PostsContainer>
            {posts.map((post) => (
              <Posts key={post.slug} post={post} />
            ))}
          </PostsContainer>
        </div>
      </div>
    </PostsPreviewContainer>
  );
};

BlogPreview.propTypes = {};

export default BlogPreview;
