import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import ReadLink from '@components/ReadLink/ReadLink';
import { truncateString } from '@utils/utilFunctions';
import {
  ImageContainer,
  ImageWrapper,
  ImageLink,
  PostDate,
  PostExcerpt,
  PostTitle,
  PostCenter,
  Tag,
  TagContainer,
  BadgeTag,
  PostHeaderRow,
  Post,
  BlogPostRight,
  BlogPostLeft,
} from './Posts.styled';

const Posts = ({ post }) => {
  // console.log('post prev', post);
  const preview = post?.excerpt?.description;
  return (
    <Post key={post?.slug}>
      <BlogPostLeft>
        <ImageLink to={`/${post.slug}/`}>
          {post.image ? (
            <ImageContainer fluid={post?.image?.fluid} alt={post?.title} />
          ) : null}
        </ImageLink>
      </BlogPostLeft>
      <BlogPostRight>
        <PostTitle>
          <Link to={`/${post?.slug}/`}>{post?.title}</Link>
        </PostTitle>
        <PostHeaderRow>
          <PostDate>{post?.date} — </PostDate>
          <TagContainer>
            {post?.tags?.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </TagContainer>
        </PostHeaderRow>
        <PostExcerpt
          dangerouslySetInnerHTML={{
            __html: preview,
          }}
        />
        <ReadLink
          to={`/${post?.slug}/`}
          text="Read this post &rarr;"
          hideRight
        />
      </BlogPostRight>
    </Post>
  );
};
Posts.propTypes = {
  post: PropTypes.object,
};
export default Posts;
