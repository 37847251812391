import PageLayout from '@global/page-layout';
import React from 'react';
import BlogHero from '@containers/BlogHero';
import BlogTags from '@containers/BlogTags';
import BlogPreview from '@containers/BlogPreview';

const BlogLandingPage = () => (
  <PageLayout title="Home">
    <BlogHero />
    {/* <BlogTags /> */}
    <BlogPreview />

    {/* Blog preview Row */}
    {/* Pagination */}
    {/* Search */}
  </PageLayout>
);

export default BlogLandingPage;
